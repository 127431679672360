<div class="container my-4 about-section">
    <div class="row py-4">
        <div class="col-lg-1"></div>
        <div class="col-lg-4 pb-5">
            <img src="assets/images/kalyani_about.jpg" alt="MyImage" class="w-100">
        </div>
        <div class="col-lg-6">
            <div class="about-text">
                <p>Kalyani Bhagat is a creative writer and screenwriter who loves to paint word pictures. She writes
                    about
                    life, people and emotions.</p>

                <p>She was born and raised in India. Although a former techie by job description, her passion for
                    writing
                    kept calling out to her and she decided to move to London for her Masters degree. She holds a
                    Diploma in
                    Creative Writing from Indira Gandhi National Open University (IGNOU), an MA in Creative Writing from
                    London Metropolitan University and an MFA in Writing for Stage and Broadcast Media from The Royal
                    Central School of Speech and Drama - University of London.</p>
                <p>She is currently working on an hour long Sci-fi TV Drama and a half hour TV Comedy series.</p>
                <p>

                    She spends her free time doing a lot of creative procrastination. She loves reading, painting and
                    singing.</p>
            </div>
        </div>
    </div>
</div>