<div class="publication-section card-group">
    <div class="container">
        <div class="row">
            <div class="col-md-4 my-4">
                <div class="card">
                    <a href="https://www.clikdapp.com/hes-so-serious-about-you" target="blank"> <img
                            src="assets/images/signs.jpg" class="card-img-top">
                    </a>

                    <div class="card-body">
                        <a href="https://www.clikdapp.com/hes-so-serious-about-you" target="blank" class="card-title">11
                            Sign He's Oh-So Serious About You
                        </a>
                        <p class="card-text">Yay! You’re dating! Are you feeling that it’s going really well? He looks
                            invested in you and seems like such an amazing guy, with killer looks...</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 my-4">
                <div class="card">
                    <a href="https://kalyanibhagat.medium.com/when-you-were-mine-e443315a9827" target="blank"> <img
                            src="assets/images/whenUWereMine.jpg" class="card-img-top">
                    </a>
                    <div class="card-body">
                        <a href="https://kalyanibhagat.medium.com/when-you-were-mine-e443315a9827" target="blank"
                            class="card-title">When You Were Mine…
                        </a>
                        <p class="card-text">And you were gone…
                            Wait, was I going to see you again? You didn’t say…
                            My heart sank… deep!! And, from the depths, it yelled and screamed. I tried to stifle the
                            pain it left in me...</p>

                    </div>
                </div>
            </div>

            <div class="col-md-4 my-4">
                <div class="card">
                    <a href="https://kalyanibhagat.medium.com/i-have-a-dream-37cbdb698424" target="blank"> <img
                            src="assets/images/dream.jpg" class="card-img-top">
                    </a>
                    <div class="card-body">
                        <a href="https://kalyanibhagat.medium.com/i-have-a-dream-37cbdb698424" target="blank"
                            class="card-title">I Have A Dream
                        </a>
                        <p class="card-text">I don’t like selling fruits. My father says I have to
                            grow up to be a fruit seller just like him. He seems fascinated by the idea of me joining
                            the family businesswhen I grow up. When he talks to his friends about... </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>