<div class="container-fluid contact-section">
  <div class="row">
    <div class="contact-form col-12 col-md-12 col-lg-7 mx-auto">
      <h2 class="text-center">THANKS FOR VISITING</h2>
      <h4 class="text-center">
        I am available for general questions/comments as well as potential
        employment opportunities in writing and editing.
      </h4>
      <form
        action="https://form.taxi/s/e87kuggh"
        id="ft-form"
        method="POST"
        accept-charset="UTF-8"
      >
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-12">
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Name*"
              name="name"
              required
            />
          </div>
          <div class="form-group col-lg-6 col-md-6 col-12">
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Email*"
              name="email"
              required
            />
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="subject"
            placeholder="Subject*"
            name="subject"
            required
          />
        </div>
        <div class="form-group">
          <textarea
            class="form-control"
            id="message"
            rows="3"
            placeholder="Message*"
            name="message"
            required
          ></textarea>
        </div>
        <div class="text-center pt-3">
          <input type="text" name="_gotcha" value="" style="display: none" />
          <button type="submit" class="btn btn-primary">Send Message</button>
        </div>
      </form>
    </div>
  </div>
</div>
