import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './app-navigation.html',
})
export class NavigationComponent {
  homePageShown: boolean = true;
  mobileLinkOpen: boolean = false;

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.homePageShown = event.url === '/home' || event.url === '/';
      }
    });
  }

  mobileNavToggle() {
    this.mobileLinkOpen = !this.mobileLinkOpen;
  }
}
