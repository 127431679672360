<div class="nav-section">
    <div class="social-links">
        <div class="d-flex flex-row-reverse bd-highlight">
            <div class="p-2 bd-highlight"> <a href="https://www.facebook.com/kalyani.bhagat.3" target="blank"
                    class="social-link px-2">
                    <ion-icon name="logo-facebook"></ion-icon>
                </a></div>
            <div class="p-2 bd-highlight"> <a href="https://www.instagram.com/kalyani_bhagat/" target="blank"
                    class="social-link px-2">
                    <ion-icon name="logo-instagram"></ion-icon>
                </a></div>
            <div class="p-2 bd-highlight"><a href="https://medium.com/@kalyanibhagat" target="blank"
                    class="social-link px-2">
                    <ion-icon src="assets/images/logo-medium.svg"></ion-icon>
                </a></div>
            <div class="p-2 bd-highlight"><a href="https://twitter.com/bhagat_kalyani" target="blank"
                    class="social-link px-2">
                    <ion-icon name="logo-twitter"></ion-icon>
                </a></div>
            <div class="p-2 bd-highlight"><a href="https://www.linkedin.com/in/kalyani-bhagat/" target="blank"
                    class="social-link px-2">
                    <ion-icon name="logo-linkedin"></ion-icon>
                </a></div>
        </div>
    </div>
    <div *ngIf="homePageShown" class="main-image row">
        <div class="col-12">
            <img src="assets/images/kalyani_home.jpg" alt="Kalyani Photo">
        </div>
    </div>
    <nav class="navbar bg-light navbar-light navbar-expand-lg">
        <div class="container-fluid navigation-bar">
            <div class="navbar-brand name-title" routerLink="/home">
                <b>KALYANI BHAGAT</b>
                <p>Creative writer | Screenwriter</p>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navBarContents">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navBarContents">
                <ul class="navbar-nav ms-auto nav-links">
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" id="about"><a
                            href="javascript:void(0)" class="nav-link" routerLink="/home"
                            routerLinkActive="active-link">Home</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" id="experience"><a
                            href="javascript:void(0)" class="nav-link" routerLink="/publications"
                            routerLinkActive="active-link">Publications</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" id="education"><a
                            href="javascript:void(0)" class="nav-link" routerLink="/about"
                            routerLinkActive="active-link">About</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" id="skills"><a
                            href="javascript:void(0)" class="nav-link" routerLink="/contact"
                            routerLinkActive="active-link">Get in Touch</a></li>
                </ul>
            </div>
        </div>
    </nav>
</div>